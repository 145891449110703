<template>
    <div  >
        <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;">
            <el-input
                    type="number"
                    placeholder="充值金额"
                    v-model="input"
                    clearable>
            </el-input>
            <div @click="wxPay">立即充值</div>
        </div>
        <div v-if="qrImgUrl != ''" style="width: 260px;height: 300px;">
            <div v-if="endTime != ''" style="text-align: center;color: red;">支付剩余时间:<count-down :endTime="endTime" :endText="endText " /></div>
            <vue-qr
                    :text="qrImgUrl"
                    :size="250"
                    :logoScale="0.2">
            </vue-qr>
            <div @click="closeWxOrder" style="text-align: center;cursor: pointer;color: #FF9800;">取消订单</div>
        </div>
    </div>

</template>

<script>
    import VueQr from "vue-qr";
    import countDown from "@/components/common/countDown";
    export default {
        name: "index",
        components:{
            VueQr,
            countDown
        },
        data(){
            return {
                qrImgUrl:"",
                input: '',
                endTime:'',//  console.log(endTime)=>得到毫秒  1658030420 可在方法里面放入后台数据,new Date('放后台数据').getTime() / 1000 +  ' ',但要记得调用方法
                endText:'',
                msg:"请完成当前订单!",
                orderNo:""
            }
        },
        mounted() {
            var that = this;
            this.checkWxPayCode();
            this.$EventBus.$on("wxPaySatus", ({ wxPaySatus }) => {
                this.$nextTick(() => {
                    if(wxPaySatus == 1){
                        that.qrImgUrl = "";
                        that.endTime = "";
                        that.orderNo = "";
                        that.input = "";
                    }
                });
            });
        },
        methods:{
            checkWxPayCode(){
                var that = this;
                that.newApi.checkWxPayCode().then((res)=>{
                    if(res.data.status == 100){
                        that.qrImgUrl = res.data.codeUrl;
                        that.endTime = res.data.endTime + '';
                        that.orderNo = res.data.orderNo;
                    }
                })
            },
            wxPay(){
                var that = this;
                if(that.input == ""){
                    return that.utils.err("请输入金额");
                }
                that.getWxPayCode();
            },
            getWxPayCode() {
                var that = this;
                that.newApi.getWxPayCode({price:that.input}).then((res) => {
                    if(res.isSuccess == 1){
                        if(res.data.status == 101){
                            that.utils.msg(that.msg);
                        }
                        that.qrImgUrl = res.data.codeUrl;
                        that.endTime = res.data.endTime + '';
                        that.orderNo = res.data.orderNo;
                    }
                })
            },
            closeWxOrder(){
                var that = this;
                that.utils.confirm("取消订单不可恢复，确认？",function () {
                    that.newApi.closeWxOrder({orderNo:that.orderNo}).then((res) => {
                        if(res.isSuccess == 1){
                            that.utils.sus(res.data);
                            that.qrImgUrl = "";
                            that.endTime = "";
                            that.orderNo = "";
                            that.input = "";
                        }
                    })
                })

            }
        }
    }
</script>

<style scoped>

</style>